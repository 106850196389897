import React from 'react'

const Maintenance = () => {
    return (
        <div style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh"
        }}>
            <h1 style={{
                fontSize: "2rem",
                color: "#f23a71",
                fontFamily: "sans-serif"
            }}>
                Site is under maintenance
            </h1>
        </div>
    )
}

export default Maintenance
