import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import './index.css';
import Main from './routes/main';
import Fleur from './routes/fleur';
import Ayeb from './routes/ayeb';
import Maintenance from './routes/Maintenance';

const inMaintenance = true;

const router = createBrowserRouter([
  {
    path: "/",
    element: <Main />
  },
  {
    path: "/fleur",
    element :<Fleur />
  },
  {
    path: "/ayeb",
    element :<Ayeb />
  }
]);

const maintenanceRouter = createBrowserRouter([
  {
    path: "/",
    element: <Maintenance />
  }
]);


ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <RouterProvider router={inMaintenance ? maintenanceRouter : router} />
  </React.StrictMode>
);