import React, { useState } from "react";
import "./fleur.css";
import { NavLink } from "react-router-dom";
import {IoClose, IoMenu, IoGameControllerOutline} from "react-icons/io5";
import { PiFlowerDuotone } from "react-icons/pi";
import { FaInstagram, FaYoutube, FaDiscord, FaTwitch} from "react-icons/fa";

export default function Fleur() {

    document.title = "FleurInestimable";
    const [isNavActive, setIsNavActive] = useState(false);

    const toggleNav = () => {
        setIsNavActive(!isNavActive);
    };

    window.addEventListener("resize", () => {
        setIsNavActive(false);
    });

    function injectScrollbarStyles() {
        var style = document.createElement('style');
        style.innerHTML = `
            /* WebKit-based browsers */
            ::-webkit-scrollbar {
            width: 8px; /* Width of the scrollbar */
            }

            ::-webkit-scrollbar-track {
            background: #0c0c0c; /* Background color of the track */
            }

            ::-webkit-scrollbar-thumb {
            background: var(--color-wrose)
            }

            ::-webkit-scrollbar-thumb:hover {
            background: white; /* Color when hovering over the scrollbar thumb */
            }

            /* Firefox */
            body {
                scrollbar-width: thin; /* Options: auto, thin */
                scrollbar-color: #0c0c0c var(--color-wrose); /* scrollbar thumb color and scrollbar track color */
            }
        `;
        document.head.appendChild(style);
    }

    injectScrollbarStyles();

    return (
        <div className="f-page">
            <header>
                <nav>
                    <NavLink to="/fleur" className="f-nav-logo">FleurInestimable</NavLink>
                    <ul className="f-nav-links">
                        <li><NavLink to="/" >Home</NavLink></li>
                        <li><a href="#music">Music</a></li>
                        <li><a href="#sports">Sports</a></li>
                        <li><a href="#socials">Socials</a></li>
                    </ul>
                </nav>
                <div className="f-nav-toggle" onClick={toggleNav}>
                    {isNavActive ? <IoClose /> : <IoMenu />}
                </div>
                <div className={`f-nav-dropdown ${isNavActive ? "active" : ""}`}>
                    <ul>
                        <li><NavLink to="/" onClick={toggleNav}>Home</NavLink></li>
                        <li><a href="#music" onClick={toggleNav}>Music</a></li>
                        <li><a href="#sports" onClick={toggleNav}>Sports</a></li>
                        <li><a href="#socials" onClick={toggleNav}>Socials</a></li>
                    </ul>
                </div>
            </header>

            <div className="f-welcome" id="welcome">
                <h1>Welcome<PiFlowerDuotone/></h1>
                <p>I'm FleurInestimable, a <span style={{color:"var(--color-wrose)"}}>musician</span>, <span style={{color:"var(--color-wrose)"}}>sporty</span> and life enthusiast. And here I <span style={{color:"var(--color-wrose)"}}>share</span> what I like with <span style={{color:"var(--color-wrose)"}}>you</span>. Enjoy !</p>
            </div>

            <section className="f-music" id="music">
                <div className="f-music-container">
                    <h1>My Music</h1>
                    <div className="f-music-content">
                        <p>I can't live <span style={{color:"var(--color-wrose)"}}>without music.</span><br/><br/>
                        I'm a musician, currently playing/learning <span style={{color:"var(--color-wrose)"}}>piano and drums</span>. I want to play as many instruments as possible, and become an accomplished <span style={{color:"var(--color-wrose)"}}>jazz musician</span>.<br/><br/>
                        I like jazz, electro, swing, latino, and more, improvising, just playing what I love, and of course, have fun! If you want to play with me or do something, contact me !<br/><br/>
                        I'm also a <span style={{color:"var(--color-wrose)"}}>music producer</span>, in the learning phase <span style={{color:"var(--color-rose)"}}>:D</span></p>
                        <iframe className="f-spotify-card" src="https://open.spotify.com/embed/artist/3ruacNrPgm29H566SxwbhP?utm_source=generator&theme=0" width="100%" height="352" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
                    </div>
                </div>
            </section>

            <section className="f-sports" id="sports">
                <div className="f-sports-container">
                    <h1>Sports</h1>
                    <div className="f-sports-content">
                    <p id='textblue'>I love <span style={{color:"var(--color-wrose)"}}>sports</span>.</p>
                            <p>I practised different sports since my childhood.<br/><br/>
                            Such as swimming, handball, volleyball. I love climbing and gymnastics. Currently, I'm mainly focused on <span style={{color:"var(--color-wrose)"}}>Calisthenics</span> .<br/><br/>
                            I'm also a <span style={{color:"var(--color-wrose)"}}>dancer</span>. Shuffle, Batchata, Hip Hop, Rock, and more !</p>
                    </div>
                </div>
            </section>

            <section className="f-socials" id="socials">
                <div className="f-socials-container">
                    <h1>My Socials</h1>
                    <div className="f-socials-content">
                        <p>
                        I try my best to share the things I do. <span style={{color:"var(--color-wrose)"}}>Spread</span> it to <span style={{color:"var(--color-wrose)"}}>bring happiness and smiles to others</span> :)
                        Do not hesitate to DM me !
                        </p>
                        <div className="f-socials-icons">
                            <a href="https://www.instagram.com/fleur_inestimable/" target="_blank" rel="noreferrer"><FaInstagram /></a>
                            <a href="https://www.youtube.com/channel/UCJx_G-auIZWurMum7JB8mXA" target="_blank" rel="noreferrer"><FaYoutube /></a>
                            <a href="https://discordapp.com/users/412538831326150658" target="_blank" rel="noreferrer"><FaDiscord /></a>
                            <a href="https://www.twitch.tv/fl3ur_1nestimable" target="_blank" rel="noreferrer"><FaTwitch /></a>
                        </div>
                    </div>
                </div>
            </section>

            <footer>
                <p>© 2024 Antoine Yebouet</p>
            </footer>
        </div>
        );
}
