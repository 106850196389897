import React from "react";
import "./projectCard.css";

const ProjectCard = ({ project}) => {
    return (
    <div className="projectCard">
        <h3 className="projectTitle" >{project.title}</h3>
        <div className="projectCardDesc">
            <img className="projectImage" src={project.image} alt={project.title} />
            <p className="projectDesc" >{project.description}</p>
        </div>
        {project.released ? <span className="projectState projreal">Released</span> : <span className="projectState projunreal" >In development</span>} 
        <button className="projectLink" onClick={() => window.open(project.link, "_blank")}
            >{project.site}</button>
    </div>
    );
};
export default ProjectCard;