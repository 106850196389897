import React, { useState } from "react";
import "./ayeb.css";
import { NavLink } from "react-router-dom";
import {IoClose, IoMenu} from "react-icons/io5";
import projects from "../projects.json";
import ProjectCard from "../projectCard";
import { FaLinkedinIn,FaGithub,FaItchIo,FaDiscord } from "react-icons/fa";
import { CiMail } from "react-icons/ci";

export default function Ayeb() {

    document.title = "Antoine Yebouet";

    const [isNavActive, setIsNavActive] = useState(false);

    const toggleNav = () => {
        setIsNavActive(!isNavActive);
    };

    window.addEventListener("resize", () => {
        setIsNavActive(false);
    });

    function injectScrollbarStyles() {
        var style = document.createElement('style');
        style.innerHTML = `
            /* WebKit-based browsers */
            ::-webkit-scrollbar {
            width: 8px; /* Width of the scrollbar */
            }

            ::-webkit-scrollbar-track {
            background: #0c0c0c; /* Background color of the track */
            }

            ::-webkit-scrollbar-thumb {
            background: var(--color-wblue)
            }

            ::-webkit-scrollbar-thumb:hover {
            background: white; /* Color when hovering over the scrollbar thumb */
            }

            /* Firefox */
            body {
                scrollbar-width: thin; /* Options: auto, thin */
                scrollbar-color: #0c0c0c var(--color-wblue); /* scrollbar thumb color and scrollbar track color */
            }
        `;
        document.head.appendChild(style);
    }

    injectScrollbarStyles();

    return(
        <div className="a-page">
            <header>
                <nav>
                    <NavLink to="/ayeb" className="a-nav-logo">Antoine Yebouet </NavLink>
                    <ul className="a-nav-links">
                        <li><NavLink to="/" >Home</NavLink></li>
                        <li><a href="#about">About Me</a></li>
                        <li><a href="#projects">Projects</a></li>
                        <li><a href="#contact">Contact</a></li>
                    </ul>
                </nav>
                <div className="a-nav-toggle" onClick={toggleNav}>
                    {isNavActive ? <IoClose /> : <IoMenu />}
                </div>
                <div className={`a-nav-dropdown ${isNavActive ? "active" : ""}`}>
                    <ul>
                        <li><NavLink to="/" onClick={toggleNav}>Home</NavLink></li>
                        <li><a href="#about" onClick={toggleNav}>About Me</a></li>
                        <li><a href="#projects" onClick={toggleNav}>Projects</a></li>
                        <li><a href="#contact" onClick={toggleNav}>Contact</a></li>
                    </ul>
                </div>
            </header>

            <section className="a-about" id="about">
                <div className="a-about-text">
                    <h1 className="a-about-start">Hi,</h1>
                    <p className="a-about-text">
                        I'm Antoine Yebouet, a <span style={{color:"var(--color-wblue)"}}>future software engineer</span>. I'm currently learning <span style={{color:"var(--color-wblue)"}}>video game development</span> at UQAC, in Québec, and I'm a student at <span style={{color:"var(--color-wblue)"}}>TELECOM Nancy</span>, in France.
                    </p>
                    <p className="a-about-text">
                        I'm always interested in discovering new technologies and tools, to improve my skills and to create new projects.
                        I've been using tools such as <span style={{color:"var(--color-wblue)"}}>Unreal Engine and Unity</span> for game development, and I'm used to work with <span style={{color:"var(--color-wblue)"}}>javascript frameworks</span> for web development.
                    </p>
                    <p className="a-about-text">
                        I also enjoy integrating my <span style={{color:"var(--color-wblue)"}}>music skills</span> in my projects, as I'm a pianist and a musician.
                        Always there to bring dynamism and creativity to a team, I'm looking for an <span style={{color:"var(--color-wblue)"}}>internship in software development</span> for the fall 2024.
                    </p>
                </div>
            </section>

            <section className="a-projects" id="projects">
                <div className="a-projects-container">
                    <h1>Projects</h1>
                    <div className="a-projects-list">
                        <h2>Non-video games projects</h2>
                        <div className="a-projects-grid">
                            {projects.others.map((project, index) => {
                                    return <ProjectCard key={index} project={project} />;
                                }
                                )}
                        </div>
                        <h2>Video games projects</h2>
                        <div className="a-projects-grid">
                            {projects.videogames.map((project, index) => {
                                    return <ProjectCard key={index} project={project} />;
                                }
                                )}
                        </div>
                    </div>
                </div>
            </section>

            <section className="a-contact" id="contact">
                <div className="a-contact-container">
                    <h1>See my work & get in touch</h1>
                    <div className="a-contact-links">
                        <a href="https://www.linkedin.com/in/antoine-yebouet-a24730234/" target="_blank" rel="noreferrer">
                            <FaLinkedinIn />
                        </a>
                        <a href="https://github.com/fl3ur1nestimable" target="_blank" rel="noreferrer">
                            <FaGithub />
                        </a>
                        <a href="https://fl3ur.itch.io" target="_blank" rel="noreferrer">
                            <FaItchIo />
                        </a>
                        <a href="https://discordapp.com/users/412538831326150658" target="_blank" rel="noreferrer">
                            <FaDiscord />
                        </a>
                        <a href="mailto:yebouetephraim@gmail.com" target="_blank" rel="noreferrer">
                            <CiMail />
                        </a>
                    </div>
                </div>
            </section>

            <footer className="a-footer">
                <p>© 2024 Antoine Yebouet</p>
            </footer>
        </div>
    ) 
}