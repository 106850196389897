import React from "react";
import "./main.css";

export default function Main() {

    const goToFleur = () => {
        window.location.href = "/fleur";
    }

    const goToAyeb = () => {
        window.location.href = "/ayeb";
    }


    return(
        <>
            <div className="mainPageUp mainPageSection">
                <h1 className="mpUpTitle">FleurInestimable</h1>
                <p className="mpUpText">Music | Sports | Socials</p>
                <button className="mpUpBtn" onClick={goToFleur}>Let's go</button>
            </div>
            <div className="mainPageDown mainPageSection">
                <h1 className="mpDownTitle">Antoine Yebouet</h1>
                <p className="mpDownText">Portfolio | Work</p>
                <button className="mpDownBtn" onClick={goToAyeb}>Enter</button>
            </div>
        </>
    ) 
}